<!--
 * @Author: lbh
 * @Date: 2022-06-17 09:18:07
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-07 14:43:32
 * @Description: 頂部 bannar 編輯
-->
<template>
  <div class="edit-card-text">
    <div class="cell-b">
      <h3>標題</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
        ></el-input>
      </div>

      <h3>副標題</h3>
      <div class="cell-box">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入副標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
        ></el-input>
      </div>

    </div>
    <div class="cell-b">

      <div
        v-for="(item,index) in configs.items"
        class="cell-b"
      >
        <!-- 操作 -->
        <el-button-group class="btn-group">
          <el-button
            type="primary"
            icon="el-icon-upload2"
            :disabled="index == 0"
            size="mini"
            @click="itemCheck(index,index-1)"
          ></el-button>
          <el-button
            type="success"
            icon="el-icon-download"
            :disabled="index == configs.items.length-1"
            size="mini"
            @click="itemCheck(index,index+1)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            :disabled="configs.items.length == 1"
            @click="itemDel(index)"
          ></el-button>
          <el-button
            type="warning"
            icon="el-icon-plus"
            size="mini"
            @click="itemAdd(index)"
          ></el-button>
        </el-button-group>
        <selfCell title="選擇移入圖標">
          <selfUpload
            v-model="item.icon"
            type="default"
            @change="setValue('items')"
            style="background: #f0f0f0"
          />
        </selfCell>
        <selfCell title="選擇默認圖標">
          <selfUpload
            v-model="item.icon2"
            type="default"
            @change="setValue('items')"
            style="background: #f0f0f0"
          />
        </selfCell>
        <selfCell title="標題">
          <div>
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
              :placeholder="`請輸入標題-${lItem.name}`"
              @input="setValue('items')"
            ></el-input>
          </div>

        </selfCell>

        <selfCell title="內容">
          <div>
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}desc`]"
              :placeholder="`請輸入內容-${lItem.name}`"
              type="textarea"
              @input="setValue('items')"
            ></el-input>
          </div>

        </selfCell>

        <selfCell title="選擇背景圖片">
          <selfUpload
            v-model="item.bgSrc"
            type="default"
            @change="setValue('items')"
          />
        </selfCell>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: 'edit-cardAndText',
  mixins: [langEditMixin],
  props: {
    type: {
      default () {
        return 'title';
      },
    },
    configs: {
      title: '',
      desc: '',
      items: [
        {
          icon: '',
          title: '',
          desc: '',
          bgSrc: '',
        },
      ],
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  data () {
    return {
      value: '',
    };
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  },
};
</script>

<style lang="less" scoped>
.edit-card-text {
  &/deep/.avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & /deep/ img {
    max-height: 300px;
  }
}
</style>
