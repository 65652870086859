import { render, staticRenderFns } from "./cardAndText.vue?vue&type=template&id=e3ab5848&scoped=true"
import script from "./cardAndText.vue?vue&type=script&lang=js"
export * from "./cardAndText.vue?vue&type=script&lang=js"
import style0 from "./cardAndText.vue?vue&type=style&index=0&id=e3ab5848&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3ab5848",
  null
  
)

export default component.exports